import type { ActionTree, GetterTree } from "vuex";
import type { IDataState } from "@/store/modules/data/index";
import { DataModules } from "@/store/modules/data/modules";
import type { ApiPathGetter } from "@/models/api";
import type { IState } from "@/store";
import { ToastProgrammatic as $toast } from "buefy";
import i18n from "@/i18n";
import type {
  IClient,
  IClientDelegate,
  IContractProduct,
  IDelegate
} from "@upmind-automation/types";
import { Methods } from "@/models/methods";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    clientId => {
      const admin = `api/admin/clients/${clientId}/delegates`;
      const client = `api/clients/${clientId}/delegates`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    },
  scope: () => (delegateId: IDelegate["id"]) => {
    return `$delegates_${delegateId}`;
  }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath(payload?.id).contextual,
        storeModule: DataModules.CLIENTS_DELEGATES
      },
      { root: true }
    );
  },
  get: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/get",
      {
        ...payload,
        path: `${getters.apiPath(payload?.clientId).contextual}/${
          payload?.delegateId
        }`,
        storeModule: DataModules.CLIENTS_DELEGATES
      },
      { root: true }
    );
  },
  create: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/create",
      {
        ...payload,
        path: getters.apiPath(payload?.id).contextual,
        storeModule: DataModules.CLIENTS_DELEGATES
      },
      { root: true }
    );
  },
  update: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/update",
      {
        ...payload,
        path: `${getters.apiPath(payload?.id).contextual}/${payload?.configId}`,
        storeModule: DataModules.CLIENTS_DELEGATES
      },
      { root: true }
    );
  },
  remove: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/remove",
      {
        ...payload,
        path: `${getters.apiPath(payload?.id).contextual}/${payload?.configId}`,
        storeModule: DataModules.CLIENTS_DELEGATES
      },
      { root: true }
    );
  },
  sendInvite: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.POST,
        path: getters.apiPath(payload?.id).contextual,
        requestConfig: { data: payload?.data }
      },
      { root: true }
    );
  },
  removeDelegate: async (
    { dispatch, getters },
    {
      clientId,
      delegateId,
      onSuccess = () => {
        $toast.open({
          message: i18n.t("_sentence.confirm.removal") as string
        });
      },
      onError = (error: any) => {
        dispatch("api/handleError", { error }, { root: true });
      }
    }: {
      clientId: IClient["id"];
      delegateId: IClientDelegate["id"];
      onSuccess: Function;
      onError: Function;
    }
  ) => {
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            autoClose: false,
            mode: "button",
            title: i18n.t("_action.remove_delegate"),
            confirmButtonText: i18n.t("_action.remove"),
            message: i18n.t("_sentence.delegates.remove_delegate_confirmation")
          },
          events: {
            confirmed: async () => {
              try {
                await dispatch(
                  "data/remove",
                  {
                    path: `${
                      getters.apiPath(clientId).contextual
                    }/${delegateId}`,
                    storeModule: DataModules.CLIENTS_DELEGATES
                  },
                  { root: true }
                );
                await onSuccess();
              } catch (error) {
                await onError(error);
              }
              confirm.close();
            }
          }
        }
      },
      { root: true }
    );
  },
  revokeCProdsAccess: async (
    { dispatch, getters },
    {
      cProdIds,
      clientId,
      delegateId,
      onSuccess = () => {
        $toast.open({
          message: i18n.t(
            "_sentence.delegates.delegate_access_successfully_revoked"
          ) as string
        });
      },
      onError = (error: any) => {
        dispatch("api/handleError", { error }, { root: true });
      }
    }: {
      cProdIds: IContractProduct["id"][];
      clientId: IClient["id"];
      delegateId: IClientDelegate["id"];
      onSuccess: Function;
      onError: Function;
    }
  ) => {
    const confirm = await dispatch(
      "ui/open/confirmModal",
      {
        config: {
          props: {
            autoClose: false,
            mode: "button",
            title: i18n.t("_.revoke_access"),
            confirmButtonText: i18n.t("_action.revoke"),
            message: i18n.t(
              "_sentence.delegates.revoke_cprod_access_confirmation"
            )
          },
          events: {
            confirmed: async () => {
              try {
                await dispatch(
                  "data/callApi",
                  {
                    method: Methods.PUT,
                    path: `${
                      getters.apiPath(clientId).contextual
                    }/${delegateId}`,
                    storeModule: DataModules.CLIENTS_DELEGATES,
                    requestConfig: {
                      data: { remove_contract_product_ids: cProdIds }
                    }
                  },
                  { root: true }
                );

                await onSuccess();
              } catch (error) {
                await onError(error);
              }
              confirm.close();
            }
          }
        }
      },
      { root: true }
    );
  },
  updateDelegate: async (
    { dispatch, getters },
    {
      clientId,
      delegateId,
      data
    }: {
      clientId: IClient["id"];
      delegateId: IClientDelegate["id"];
      data: any; // change
    }
  ) => {
    return dispatch(
      "data/callApi",
      {
        method: Methods.PUT,
        path: `${getters.apiPath(clientId).contextual}/${delegateId}`,
        storeModule: DataModules.CLIENTS_DELEGATES,
        requestConfig: { data }
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  modules: {
    products: require("./delegateProducts").default
  }
};
