import type { ActionTree, GetterTree } from "vuex";
import type { IDataState } from "@/store/modules/data/index";
import { DataModules } from "@/store/modules/data/modules";
import type { ApiPathGetter } from "@/models/api";
import type { IState } from "@/store";

const initialState = {} as IDataState;

const getters: GetterTree<IDataState, IState> = {
  apiPath:
    (s, g, rS, { isAdminContext, isMockClientContext }): ApiPathGetter =>
    ({ clientId, delegateId }) => {
      const admin = `api/admin/clients/${clientId}/delegates/${delegateId}/contract_products`;
      const client = `api/clients/${clientId}/delegates/${delegateId}/contract_products`;
      const contextual =
        isAdminContext && !isMockClientContext() ? admin : client;
      return { client, admin, contextual };
    }
  // scope: () => (delegateId: IDelegate["id"]) => {
  //   return `$delegates_${delegateId}`;
  // }
};

const actions: ActionTree<IDataState, IState> = {
  list: ({ dispatch, getters }, payload) => {
    return dispatch(
      "data/list",
      {
        ...payload,
        path: getters.apiPath({
          clientId: payload?.clientId,
          delegateId: payload?.delegateId
        }).contextual,
        storeModule: DataModules.CLIENTS_DELEGATE_CONTRACT_PRODUCTS
      },
      { root: true }
    );
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions
};
