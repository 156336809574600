<template>
  <loading v-if="uiLoading" :is-absolute="true" />
  <router-view v-else-if="isValidOAuthClient" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import _ from "@/boot/lodash";
import { install as gtmInstall } from "@/boot/gtm";
import Context from "@/mixins/context";
import { BrandConfigKeys } from "@/data/constants";
import type { IState } from "@/store";
import type { IUser } from "@/models/users";
import type { BModalComponent } from "buefy/types/components";

export default defineComponent({
  name: "App",
  mixins: [Context],
  data: () => ({
    invalidOAuthClientModal: null as BModalComponent | null
  }),
  computed: {
    ...mapState({
      metaTitles: (state: IState): (string | Function)[] =>
        state.ui.meta.titles,
      uiLoading: (state: IState): boolean => state.ui.loading,
      user: (state: IState): IUser => state.user
    }),
    ...mapGetters({
      brandColor: "brand/color",
      brandColorContrast: "brand/contrastColorHex",
      brandFavicon: "brand/favicon",
      brandFontFamily: "brand/fontFamily",
      isMultibrand: "brand/isMultibrand",
      isValidOAuthClient: "brand/isValidOAuthClient"
    }),
    brandName() {
      return this.$store.getters["brand/name"]();
    },
    hasExpiredSession() {
      return this.$store.getters["auth/hasExpiredSession"](this.context);
    },
    metaProps(): object {
      return _.merge({}, this.$route.params, this.$store.state.ui.meta.props);
    },
    metaTitlesMapped() {
      return _.map(this.metaTitles, title => {
        return typeof title === "function"
          ? title(this.metaProps)
          : this.$i18n.t(title, this.metaProps);
      });
    },
    metaTitle(): string {
      const isAdminApp: boolean = this.$route.path.startsWith("/admin");
      const prepend: string = isAdminApp
        ? `${this.$i18n.t("_.upmind")} –`
        : `${this.brandName} –`;
      const parts: string = this.metaTitlesMapped.join(" | ");
      const append: string =
        isAdminApp && this.brandName ? ` (${this.brandName})` : ``;
      return _.trimEnd(`${prepend}${parts ? ` ${parts}` : ``}${append}`, ` – `);
    }
  },
  watch: {
    hasExpiredSession: { handler: "sessionExpired" },
    brandColor: { handler: "brandColorChange", immediate: true },
    brandFavicon: { handler: "onFaviconChange", immediate: true },
    brandFontFamily: { handler: "onFontFamilyChange", immediate: true },
    isValidOAuthClient: { handler: "onOAuthValidityChange", immediate: true },
    metaTitle: { handler: "metaTitleChanged" }
  },
  created() {
    // Initialise Google Tag Manager
    this.isAdmin ? this.initAdminGtm() : this.initGtm();

    this.$bus.$on(
      "admin/dynamicSettings/updated",
      this.onDynamicSettingsChange
    );
  },
  beforeDestroy() {
    this.$bus.$off(
      "admin/dynamicSettings/updated",
      this.onDynamicSettingsChange
    );
  },
  mounted() {
    const appLoader = document.getElementById("app-loading");
    if (appLoader && appLoader.parentNode) {
      appLoader.parentNode.removeChild(appLoader);
    }
  },
  methods: {
    sessionExpired(isExpired: boolean) {
      if (isExpired && !_.isEmpty(this.user)) {
        this.$store
          .dispatch("auth/tryReAuthentication", this.context)
          .catch(() => {
            this.$store.dispatch("auth/handleReAuthFail", this.context);
          });
      }
    },
    brandColorChange() {
      this.setBrandColor();
    },
    onFaviconChange() {
      this.setBrandFavicon();
    },
    onFontFamilyChange() {
      this.setBrandFontFamily();
    },
    async onOAuthValidityChange(isValid) {
      if (!isValid && !this.invalidOAuthClientModal) {
        // Here we prevent a modal flash when logging out from being in
        // multibrand mode or in the context of a different brand
        if (this.$route?.path?.includes("/logout")) return;
        this.invalidOAuthClientModal = await this.$store.dispatch(
          "ui/openInvalidOAuthClientModal"
        );
      } else if (isValid && this.invalidOAuthClientModal) {
        await this.invalidOAuthClientModal.close();
        this.invalidOAuthClientModal = null;
      }
    },
    metaTitleChanged() {
      document.title = this.metaTitle;
    },
    async initAdminGtm() {
      try {
        if (!this.isAdmin) return;
        if (import.meta.env.VITE_APP_ADMIN_GTM_ENABLED !== "true") return;
        const containerId = import.meta.env.VITE_APP_ADMIN_GTM_CONTAINER_ID;
        if (containerId.length) gtmInstall(containerId);
      } catch {
        // Catch error as not to break app
      }
    },
    async initGtm() {
      try {
        if (this.isAdmin) return;
        const containerId =
          this.$store.getters["brand/config"][
            BrandConfigKeys.ANALYTICS_GTM_CONTAINER_ID
          ];
        if (containerId.length) gtmInstall(containerId);
      } catch {
        // Catch error as not to break app
      }
    },
    /**
     * Fetches changed BrandConfigKeys from BE when dynamic field is changed
     */
    onDynamicSettingsChange(payload: {
      form: { diff: { [key: string]: any } };
    }) {
      const configValues = _.values(BrandConfigKeys).map(i => i.toString());
      const changedKeys = _.keys(payload.form.diff);

      const overlappedKeys = changedKeys.filter(key =>
        configValues.includes(key)
      );

      if (overlappedKeys.length) {
        this.$store.dispatch("brand/getConfig", {
          keys: overlappedKeys,
          brandId: this.$store.state.brand?.id,
          ignoreStored: true
        });
      }
    },
    setBrandColor() {
      document.documentElement.style.setProperty(
        "--brand-color",
        ` ${this.brandColor}`
      );
      document.documentElement.style.setProperty(
        "--brand-color-contrast",
        ` ${this.brandColorContrast}`
      );
    },
    setBrandFavicon() {
      if (this.brandFavicon === undefined && !this.isMultibrand) return;
      const types = ["icon", "apple-touch-icon"];
      for (const type of types) {
        let link = document.querySelector(`link[rel~='${type}']`);
        if (!link) {
          link = document.createElement("link");
          link.setAttribute("rel", type);
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.setAttribute(
          "href",
          this.brandFavicon
            ? this.brandFavicon + "?size=32x32"
            : "/assets/images/favicons/32x32.png"
        );
      }
    },
    setBrandFontFamily() {
      if (!this.brandFontFamily) return;
      const fontUrl = new URL("https://fonts.googleapis.com/css");
      fontUrl.searchParams.set("family", this.brandFontFamily);
      fontUrl.searchParams.set("display", "swap");
      const link = document.createElement("link");
      link.setAttribute("rel", "stylesheet");
      link.setAttribute("href", fontUrl.toString());
      document.head.appendChild(link);
      document.documentElement.style.setProperty(
        "--brand-font-family",
        ` ${this.brandFontFamily}`
      );
    }
  }
});
</script>

<style lang="scss" src="./assets/sass/bulma/custom-build.scss" />
