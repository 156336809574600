import type { Route } from "vue-router";
import { AdminRoutes, RegexMatch } from "@/data/enums/router";

export default [
  {
    path: "accounts",
    name: AdminRoutes.CLIENT_ACCOUNTS,
    redirect: { name: AdminRoutes.CLIENT_ACCOUNTS_AFFILIATE_ACCOUNT },
    component: () => import("@/views/admin/clients/client/accounts/index.vue"),
    meta: {
      title: "_.accounts",
      backTo: "_.accounts"
    },
    children: [
      {
        path: "affiliate",
        name: AdminRoutes.CLIENT_ACCOUNTS_AFFILIATE_ACCOUNT,
        redirect: {
          name: AdminRoutes.CLIENT_ACCOUNTS_AFFILIATE_ACCOUNT_OVERVIEW
        },
        component: () =>
          import("@/views/admin/clients/client/accounts/affiliate/index.vue"),
        props: () => ({
          routes: require("./menu").affiliateAccountTabigation
        }),
        children: [
          {
            path: "overview",
            name: AdminRoutes.CLIENT_ACCOUNTS_AFFILIATE_ACCOUNT_OVERVIEW,
            component: () =>
              import(
                "@/views/admin/clients/client/accounts/affiliate/overview/index.vue"
              ),
            meta: {
              backTo: "_.overview"
            }
          },
          {
            path: "commissions",
            name: AdminRoutes.CLIENT_ACCOUNTS_AFFILIATE_ACCOUNT_COMMISSIONS,
            component: () =>
              import(
                "@/views/admin/clients/client/accounts/affiliate/commissions/index.vue"
              ),
            meta: {
              backTo: "_.commissions"
            }
          },
          {
            path: "payouts",
            name: AdminRoutes.CLIENT_ACCOUNTS_AFFILIATE_ACCOUNT_PAYOUTS,
            component: () =>
              import(
                "@/views/admin/clients/client/accounts/affiliate/payouts/index.vue"
              ),
            meta: {
              backTo: "_.payouts"
            }
          },
          {
            path: "settings",
            name: AdminRoutes.CLIENT_ACCOUNTS_AFFILIATE_ACCOUNT_SETTINGS,
            component: () =>
              import(
                "@/views/admin/clients/client/accounts/affiliate/settings/index.vue"
              ),
            meta: {
              backTo: "_.settings"
            }
          }
        ]
      },
      {
        path: "client-accounts",
        name: AdminRoutes.CLIENT_ACCOUNTS_CHILD_ACCOUNTS,
        component: () =>
          import(
            "@/views/admin/clients/client/accounts/childClients/index.vue"
          ),
        meta: {
          title: "_.child_accounts",
          backTo: "_.child_accounts"
        }
      },
      {
        path: "delegates",
        name: AdminRoutes.CLIENT_ACCOUNTS_DELEGATES,
        component: () =>
          import("@/views/admin/clients/client/accounts/delegates/index.vue"),
        meta: {
          title: "_.delegates",
          backTo: "_.delegates"
        }
      },
      {
        path: `delegates/:delegateId(${RegexMatch.UUID})`,
        name: AdminRoutes.CLIENT_ACCOUNTS_DELEGATE_OVERVIEW,
        component: () =>
          import(
            "@/views/admin/clients/client/accounts/delegates/delegate/index.vue"
          ),
        props: (to: Route) => ({
          clientId: to.params.clientId,
          delegateId: to.params.delegateId
        }),
        meta: {
          title: "_.manage_delegate",
          backTo: "_.manage_delegate"
        }
      }
    ]
  }
];
