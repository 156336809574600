import NProgress from "nprogress";
import _ from "@/boot/lodash";
import i18n from "@/i18n";
import store from "@/store";
import type { BModalConfig } from "buefy/types/components";
import { Contexts } from "@upmind-automation/types";
import type { IState } from "@/store";
import { ListDisplayMode, BrandConfigKeys } from "@/data/constants";
import { ModalProgrammatic as $modal } from "buefy";
import { ModalTypes } from "@/data/enums";
import type { MutationTree, ActionTree, GetterTree } from "vuex";
import { SlideProgrammatic as $slide } from "@/plugins/Slide";
import { SnackbarProgrammatic as $snackbar } from "buefy";
import { ToastProgrammatic as $toast } from "buefy";
import { UserMetaKeys } from "@/data/enums/users";
import { UPM_DATA_LAYER } from "@/boot/gtm";
import { setSessionItem, getSessionItem } from "@/helpers/sessionStorage";
import type { CreateElement } from "vue";

NProgress.configure({ showSpinner: false, speed: 400 });

export enum NewLine {
  ENTER = "ENTER",
  SHIFT_AND_ENTER = "SHIFT_AND_ENTER"
}

export interface IUiState {
  breadcrumb?: any;
  loading: boolean;
  searchPane: boolean;
  notificationIsFocused: boolean;
  openModals: string[];
  previousRoutes: {
    path: string;
    fullPath: string;
    backTo: string;
  }[];
  sliders: any[];
  window: {
    width: number;
    height: number;
    visible: boolean;
  };
  meta: {
    titles: string[];
    props: object;
  };
  nav: {
    isOpen: boolean;
    isOverlaid: boolean;
  };
}

const initialState = (): IUiState => {
  return {
    loading: true,
    searchPane: false,
    previousRoutes: _.isArray(getSessionItem("previousRoutes", []))
      ? getSessionItem("previousRoutes", [])
      : [],
    sliders: [],
    notificationIsFocused: false,
    openModals: [],
    window: {
      width: 0,
      height: 0,
      visible: document.visibilityState === "visible"
    },
    meta: {
      titles: [],
      props: {}
    },
    nav: {
      isOverlaid: false,
      isOpen: false
    }
  };
};

const getters: GetterTree<IUiState, IState> = {
  secondaryNav: (state, getters, rootState, rootGetters) => {
    return rootGetters["user/meta"](UserMetaKeys.UI_MENU) === "true";
  },
  navIsCollapsed: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters["user/meta"](UserMetaKeys.UI_NAV_IS_COLLAPSED) === "true"
    );
  },
  autoRefresh: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters["user/meta"](UserMetaKeys.UI_SUPPORT_AUTO_REFRESH) ?? true
    );
  },
  newLine: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters["user/meta"](UserMetaKeys.UI_SUPPORT_NEW_LINE) ||
      rootGetters["brand/config"][BrandConfigKeys.UI_ENTER_KEY_ACTION] ||
      NewLine.ENTER
    );
  },
  displayMode: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters["user/meta"](UserMetaKeys.UI_SUPPORT_DISPLAY_MODE) ||
      ListDisplayMode.TABLE
    );
  },
  limit: (state, getters, rootState, rootGetters) => {
    return (
      _.toNumber(rootGetters["user/meta"](UserMetaKeys.UI_SUPPORT_LIMIT)) || 10
    );
  },
  redirectToAllTickets: () => {
    const value = store?.getters["user/meta"](
      UserMetaKeys.UI_SUPPORT_REDIRECT_TO_ALL_TICKETS
    );
    return !!_.toNumber(_.isNil(value) ? 1 : value);
  },
  showOwnerTags: (s, g, rS, rootGetters) => {
    return (
      rootGetters["user/meta"](UserMetaKeys.UI_SUPPORT_SHOW_OWNER_TAGS) ?? true
    );
  },
  submitWithShortcut: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters["user/meta"](UserMetaKeys.UI_SUPPORT_SUBMIT_WITH_SHORTCUT) ??
      false
    );
  },
  isMobileXs: state => {
    return state.window.width <= 512;
  },
  isMobile: state => {
    return state.window.width <= 768;
  },
  isTabletPlus: state => {
    return state.window.width >= 769;
  },
  isTablet: state => {
    return state.window.width >= 769 && state.window.width < 1024;
  },
  isTouch: state => {
    return state.window.width < 1024;
  },
  isDesktopPlus: state => {
    return state.window.width >= 1024;
  },
  isDesktop: state => {
    return state.window.width >= 1024 && state.window.width < 1216;
  },
  isWidescreenPlus: state => {
    return state.window.width >= 1216;
  },
  isWidescreen: state => {
    return state.window.width >= 1216 && state.window.width < 1472;
  },
  isFullHd: state => {
    return state.window.width >= 1472;
  },
  isVisible: state => {
    return state.window.visible;
  },
  previousRoute: state => {
    return _.last(state.previousRoutes);
  }
};

let timeout;

const actions: ActionTree<IUiState, IState> = {
  secondaryNav: ({ getters, dispatch }, payload) => {
    const secondaryNav = payload || getters.secondaryNav();
    dispatch(
      "user/addMeta",
      { [UserMetaKeys.UI_MENU]: secondaryNav.toString() },
      { root: true }
    );
  },
  collapseNav: ({ getters, dispatch }, payload?: boolean) => {
    const collapse =
      typeof payload === "boolean" ? payload : !getters.navIsCollapsed;
    dispatch(
      "user/addMeta",
      { [UserMetaKeys.UI_NAV_IS_COLLAPSED]: collapse.toString() },
      { root: true }
    );
  },
  startRouting: () => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      NProgress.start();
    }, 50);
  },
  endRouting: ({ state, commit }) => {
    if (timeout) clearTimeout(timeout);
    if (state.loading) commit("loading", false);
    NProgress.done();
  },
  reload: () => {
    window.location.reload();
  },
  showErrorMessage: (context, errorMessage) => {
    $toast.open({
      message: errorMessage,
      type: "is-danger"
    });
  },
  showErrorSnackbar: (
    context,
    { errorMessage, actionText = i18n.t("_action.dismiss") }
  ) => {
    $snackbar.open({
      message: errorMessage,
      actionText,
      type: "is-danger",
      indefinite: true
    });
  },
  openModal: (
    { rootState },
    { type, config }: { type: ModalTypes; config: BModalConfig }
  ) => {
    const defaultConfig = {
      parent: window.$rootVue,
      hasModalCard: true,
      width: 800
    };

    if (!_.values(ModalTypes).includes(type)) {
      type =
        rootState.context === Contexts.ADMIN
          ? ModalTypes.SLIDE
          : ModalTypes.WINDOW;
    } else if (type === ModalTypes.CONFIRM) {
      _.merge(defaultConfig, {
        component: () => import("@/components/base/ConfirmModal.vue"),
        canCancel: [],
        width: 500
      });
    }
    UPM_DATA_LAYER.push({ event: "upmind.modal_open" });
    const finalConfig = _.merge({}, defaultConfig, config);
    if (type === ModalTypes.SLIDE) {
      return $slide.slide(finalConfig);
    }
    return $modal.open(finalConfig);
  },
  openShowDocsPreviewModal: ({ dispatch }, payload) => {
    dispatch(
      "ui/open/slideModal",
      {
        config: {
          component: () =>
            import("@/components/app/global/docs/showDocsPreviewModal.vue"),
          ...payload
        }
      },
      { root: true }
    );
  },
  open503ErrorModal: ({ dispatch }) => {
    dispatch(
      "ui/open/windowModal",
      {
        config: {
          width: 540,
          canCancel: [],
          component: {
            /** Here we intentionally use the imported `i18n` instance, as
             * there's a risk Vue.prototype globals (eg. $t, $store etc) will
             * not be ready or available when this template is rendered. */
            render: (h: CreateElement) =>
              h(
                "modal-card",
                {
                  class: "is-caution",
                  props: {
                    title: i18n.t("_.error_code.503")
                  },
                  scopedSlots: {
                    footer: () =>
                      h(
                        "button",
                        {
                          class: "button",
                          on: {
                            click: () => dispatch("reload")
                          }
                        },
                        [i18n.t("_action.reload_page") as string]
                      )
                  }
                },
                [i18n.t("_sentence.error_code.503") as string]
              )
          }
        }
      },
      { root: true }
    );
  },
  open5XXErrorModal: ({ dispatch }) => {
    dispatch(
      "ui/open/windowModal",
      {
        config: {
          width: 540,
          canCancel: [],
          component: {
            /** Here we intentionally use the imported `i18n` instance, as
             * there's a risk Vue.prototype globals (eg. $t, $store etc) will
             * not be ready or available when this template is rendered. */
            render: (h: CreateElement) =>
              h(
                "modal-card",
                {
                  class: "is-danger",
                  props: {
                    title: i18n.t("_.error_code.500")
                  },
                  scopedSlots: {
                    footer: () =>
                      h(
                        "button",
                        {
                          class: "button",
                          on: {
                            click: () => dispatch("reload")
                          }
                        },
                        [i18n.t("_action.reload_page") as string]
                      )
                  }
                },
                [i18n.t("_sentence.error_code.500") as string]
              )
          }
        }
      },
      { root: true }
    );
  },
  openInvalidOAuthClientModal: ({ dispatch, rootGetters }) => {
    return dispatch(
      "ui/open/windowModal",
      {
        config: {
          width: 440,
          canCancel: [],
          component: {
            render: (h: CreateElement) =>
              h(
                "modal-card",
                {
                  class: "is-danger",
                  props: {
                    title: i18n.t("_error.403"),
                    showFooter: false
                  }
                },
                [
                  i18n.t("_sentence.error_code.403_oauth_context", {
                    hostname: window.location.hostname,
                    brandName: rootGetters["brand/name"]()
                  }) as string
                ]
              )
          }
        }
      },
      { root: true }
    );
  },
  openNetworkErrorModal: ({ dispatch }) => {
    dispatch(
      "ui/open/windowModal",
      {
        config: {
          width: 540,
          canCancel: [],
          component: {
            /** Here we intentionally use the imported `i18n` instance, as
             * there's a risk Vue.prototype globals (eg. $t, $store etc) will
             * not be ready or available when this template is rendered. */
            render: (h: CreateElement) =>
              h(
                "modal-card",
                {
                  class: "is-danger",
                  props: {
                    title: i18n.t("_.error_code.network")
                  },
                  scopedSlots: {
                    footer: () =>
                      h(
                        "button",
                        {
                          class: "button",
                          on: {
                            click: () => dispatch("reload")
                          }
                        },
                        [i18n.t("_action.reload_page") as string]
                      )
                  }
                },
                [i18n.t("_sentence.error_code.network") as string]
              )
          }
        }
      },
      { root: true }
    );
  },
  openBlockedIPModal: (
    { commit, dispatch, rootGetters, state },
    errorMessage = ""
  ) => {
    const modalCode = "blockedIPModal";
    if (state.openModals.includes(modalCode)) return;
    dispatch(
      "ui/open/windowModal",
      {
        config: {
          width: 540,
          canCancel: [],
          component: {
            created() {
              commit("openModal", modalCode);
            },
            /** Here we intentionally use the imported `i18n` instance, as
             * there's a risk Vue.prototype globals (eg. $t, $store etc) will
             * not be ready or available when this template is rendered. */
            render: (h: CreateElement) =>
              h(
                "modal-card",
                {
                  class: "is-danger",
                  props: {
                    title: i18n.t("_.error_code.403_ip_blocked_context")
                  },
                  scopedSlots: {
                    footer: () =>
                      h(
                        "button",
                        {
                          class: "button",
                          on: {
                            click: () => dispatch("reload")
                          }
                        },
                        [i18n.t("_action.reload_page") as string]
                      )
                  }
                },
                [
                  i18n.t("_sentence.error_code.403_ip_blocked_context", {
                    errorMessage: errorMessage?.replace(/\W+$/, ""),
                    brandName: rootGetters["brand/name"]()
                  }) as string
                ]
              )
          }
        }
      },
      { root: true }
    );
  },
  openAllowPopupsModal: (
    { dispatch },
    continueInWindow: Function = () => ({})
  ) => {
    dispatch(
      "ui/open/windowModal",
      {
        config: {
          width: 480,
          canCancel: ["button", "escape"],
          component: {
            template: `
            <modal-card class="is-caution" :title="$t('_.please_enable_popups')" cancel-text="_.close">
              <u-i18n path="_sentence.action_requires_popups">
                <template #hostname>
                  <code>${window.location.hostname}</code>
                </template>
              </u-i18n>
              <button slot="footer" class="button" @click="$emit('continueInWindow')">
                {{ $t("_action.continue_in_window") }}
              </button>
            </modal-card>`
          },
          events: {
            continueInWindow
          }
        }
      },
      { root: true }
    );
  },
  copyToClipboard: async (context, text: string) => {
    try {
      await window.$rootVue.$copyText(text);
      $toast.open({
        message: i18n.t("_sentence.confirm.copy") as string
      });
    } catch {
      // Slient catch
    }
  }
};

const mutations: MutationTree<IUiState> = {
  loading: (state, payload) => {
    state.loading = payload;
  },
  setSupportPrefs: (state, p: Record<string, any> | Record<string, any>[]) => {
    _.each(_.isArray(p) ? p : [p], ({ key, value }) =>
      _.set(state.support, key, value)
    );
  },
  overlayNav: (state, payload: boolean) => {
    state.nav.isOverlaid = payload;
  },
  openNav: (state, payload) => {
    state.nav.isOpen =
      typeof payload === "boolean" ? payload : !state.nav.isOpen;
  },
  addPreviousRoute: (state: IUiState, route) => {
    // Skip routes matching the following pattern
    const routesToSkip =
      /^\/admin(\/auth)?\/(login|forgotten-password|reset-password|verify|logout)/gi;
    if (route.path.match(routesToSkip)) return;
    // Enforce max limit
    const maxRoutes = 50;
    if (state.previousRoutes.length > maxRoutes)
      state.previousRoutes.splice(0, 1);
    // Add route
    state.previousRoutes.push(route);
    setSessionItem("previousRoutes", state.previousRoutes, true);
  },
  popPreviousRoute: (state: IUiState) => {
    state.previousRoutes.pop();
    setSessionItem("previousRoutes", state.previousRoutes, true);
  },
  openModal: (state, modalCode: string) => {
    state.openModals = _.uniq([...state.openModals, modalCode]);
  },
  searchPane: (state, payload) => {
    state.searchPane =
      typeof payload === "boolean" ? payload : !state.searchPane;
  },
  window: (state, { width, height, visible }) => {
    state.window = { width, height, visible };
  },
  setMetaTitles: (state, titles) => {
    _.set(state.meta, "titles", titles);
  },
  setNotificationIsFocused: (state, isFocused: boolean) => {
    state.notificationIsFocused = isFocused;
  },
  setMetaProps: (state, props) => {
    const metaProps = _.merge({}, state.meta.props, props);
    _.set(state.meta, "props", metaProps);
  },
  unsetMetaProps: (state, keys) => {
    const metaProps = _.omit(state.meta.props, keys);
    _.set(state.meta, "props", metaProps);
  },
  setSlider: (state, id) => {
    state.sliders.push(id);
  },
  unsetSlider: (state, id) => {
    const index = state.sliders.indexOf(id);
    state.sliders.splice(index, 1);
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
  modules: {
    breadcrumb: require("./breadcrumb").default,
    featurebase: require("./featurebase").default,
    hooks: require("./hooks").default,
    open: require("./open").default,
    router: require("./router").default,
    userflow: require("./userflow").default
  }
};
